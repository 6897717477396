import '../stylesheet/App.scss';
import LandingPage from './LandingPage';

function App() {
  return (
    <LandingPage />
  );
}

export default App;
